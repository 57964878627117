import { getClient } from 'utils/utils';
import demoConfig from './demoConfig';
import dollarGeneralConfig from './dollarGeneralConfig';
import dollarGeneralSupportConfig from './dollarGeneralSupportConfig';
import emboldConfig from './emboldConfig';
import emsanaCareConfig from './emsanaCareConfig';
import icubaConfig from './icubaConfig';
import microsoftConfig from './microsoftConfig';
import polkConfig from './polkConfig';
import surgeryPlusConfig from './surgeryPlusConfig';
import walmartConfig from './walmartConfig';
import bnsfConfig from './bnsfConfig';
import jbhuntConfig from './jbhuntConfig';
import soctConfig from './soctConfig';
import clrConfig from './clrConfig';
import customerDemoConfig from './customerDemoConfig';
import soctProviderLookupConfig from './soctProviderLookupConfig';
import quantumConfig from './quantumConfig';
import ensembleConfig from './ensembleConfig';
import ensembleProviderLookupConfig from './ensembleProviderLookupConfig';
import walmartNationalConfig from './walmartNationalConfig';
import convivaConfig from './convivaConfig';
import jpmcConfig from './jpmcConfig';
import referralGuideDemoConfig from './referralGuideDemoConfig';
import bcbsNorthCarolinaConfig from './BCBSNorthCarolinaConfig';
import axaConfig from './axaConfig';

export default function getClientConfig(clientNameParam) {
  let client = getClient();

  if (client === 'local') {
    client = clientNameParam || localStorage.getItem('devTools:client') || 'demo';

    if (window.location.href.includes('/override')) {
      localStorage.removeItem('devTools:client');
      localStorage.removeItem('devTools:apiUrl');
      localStorage.removeItem('integrationToken');
      window.location.pathname = '/';
    }
  }

  switch (client) {
    case 'walmart':
      return walmartConfig;
    case 'walmart-national':
      return walmartNationalConfig;
    case 'microsoft':
      return microsoftConfig;
    case 'polk':
      return polkConfig;
    case 'demo':
      return demoConfig;
    case 'customerdemo':
      return customerDemoConfig;
    case 'embold':
      return emboldConfig;
    case 'icuba':
      return icubaConfig;
    case 'bcbst-support':
      return dollarGeneralSupportConfig;
    case 'bcbst':
      return dollarGeneralConfig;
    case 'emsanacare':
      return emsanaCareConfig;
    case 'surgeryplus':
      return surgeryPlusConfig;
    case 'bnsf':
      return bnsfConfig;
    case 'jbhunt':
      return jbhuntConfig;
    case 'carecompass':
      return soctConfig;
    case 'carecompass-providerlookup':
      return soctProviderLookupConfig;
    case 'continental':
      return clrConfig;
    case 'ensemblehp':
      return ensembleConfig;
    case 'ensemblehp-providerlookup':
      return ensembleProviderLookupConfig;
    case 'quantum':
      return quantumConfig;
    case 'conviva':
      return convivaConfig;
    case 'referralguide-demo':
      return referralGuideDemoConfig;
    case 'jpmc-cigna':
      return jpmcConfig;
    case 'northcarolina':
      return bcbsNorthCarolinaConfig;
    case 'axa-partners':
      return axaConfig;
    case 'feature-three':
      return demoConfig;
    case 'feature-two':
      return microsoftConfig;
    case 'feature-one':
      return quantumConfig;
    default:
      console.error('Unhandled client configuration case');
      return demoConfig;
  }
}
