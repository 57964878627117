/* eslint-disable no-shadow, react/jsx-filename-extension */
import React from 'react';
import { createSelector } from '@reduxjs/toolkit/';
import { memoize, capitalize } from 'lodash';

import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';

import { distance, resultIsProviderType, getTierDesignationContent } from 'utils/utils';
import {
  COST_EFFICIENCY_MAP,
  HIGH_PERFORMING_TITLE,
  HIGH_PERFORMING_TOOLTIP_TEXT,
  PERCENTILE_THRESHOLD,
  SCORE_COLORS_BY_TIER,
  SCORE_TIER_HIGH,
  SCORE_TIER_LANGUAGE,
  SCORE_TIER_MIDDLE,
  SCORE_TIER_UNSCORED,
  SCORE_TIER_ICON_COMPONENTS,
  SUBSPECIALTY_TYPES,
  SOCT,
  SOCT_PROVIDER_LOOKUP,
} from 'utils/constants';
import * as selectResults from './selectResults';
import * as selectNetworks from '../config/selectNetworks';
import * as selectClient from '../config/selectClient';
import * as selectFeatureFlags from '../config/selectFeatureFlags';
import * as selectContent from '../config/selectContent';
import * as selectLocation from '../location/selectLocation';
import { getNetworkContent } from './providerUtils';
import { AFFILIATION_TYPES } from './providerConstants';

/* ************************************************ */
/* **** Initial selectProviderById Function ******* */
/* ************************************************ */

/** This function selects a result by id from the results object. It verifies that the given result exists, AND is a provider object.
 *  @param {string} id @returns {Function} A selector function
 * */
export const providerById = memoize((id) =>
  createSelector([selectResults.all], (resultObject) => {
    const provider = resultObject[id];
    if (!provider) return undefined;

    if (!resultIsProviderType(resultObject[id])) {
      return undefined;
    }
    return provider;
  })
);

/* ************************************************ */
/* *********** Computed Properties **************** */
/* ************************************************ */
/** @returns {Function} */
function fullNameAndTitle(id) {
  return createSelector([providerById(id)], (provider) => {
    if (!provider) return '';
    const { firstName, lastName, title } = provider;

    let result = `${firstName} ${lastName}`;
    if (title) result += `, ${title}`;
    return result;
  });
}

/**
 * returns -1 if not accepting new patients AND some accepting_new_patients are undefined, otherwise returns number greater than -1
 * @param {Number} id - provider id
 * @returns {function(): Number}
 */
function numberOfLocationsAcceptingNewPatients(id) {
  return createSelector([providerById(id)], (provider) => {
    if (!provider) return '';
    const { places } = provider;

    const numberOfLocationsAcceptingNewPatients = places.reduce(
      (acc, loc) => acc + (loc.acceptingNewPatients ? 1 : 0),
      0
    );

    const placesHaveUndefined = places.some((loc) => loc.acceptingNewPatients === undefined);

    return placesHaveUndefined && numberOfLocationsAcceptingNewPatients === 0
      ? -1
      : numberOfLocationsAcceptingNewPatients;
  });
}

/** @returns {Function} */
function hasTelehealthAvailable(id) {
  return createSelector([providerById(id)], (provider) => {
    if (!provider) return '';
    const { places } = provider;

    const hasTelehealthAvailable = places.some((p) => !!p?.telehealthAvailable);
    return hasTelehealthAvailable;
  });
}

/** @returns {Function} */
function tierDesignationRollup(id) {
  return createSelector([providerById(id), selectNetworks.currentSlug], (provider, networkSlug) => {
    if (!provider) return null;

    const tierValueMap = {
      1: 1,
      2: 2,
      'Non-Tiered': 3,
    };

    const { places } = provider;

    const tier = places.reduce((acc, { tiering }) => {
      if (!tiering) return acc;
      const tier = tiering[networkSlug];
      if (tierValueMap[tier] && (!acc || tier < acc)) return tier;
      return acc;
    }, null);

    if (!tier) return null;
    return getTierDesignationContent(tier);
  });
}

/** @returns {Function} */
function isPcp(id) {
  return createSelector([providerById(id), selectNetworks.currentSlug], (provider, networkSlug) => {
    if (!provider) return null;

    return Boolean(provider.isPcp?.[networkSlug]);
  });
}

/** @returns {Function} */
function networkContent(id) {
  return createSelector(
    [providerById(id), selectNetworks.currentSlug, selectClient.name],
    (provider, network, client) => {
      if (!provider) return '';

      return getNetworkContent({ provider, network, client });
    }
  );
}

/**
 * This selector accepts state and returns a function that can be used to calculate a dynamic provider score or return null if the feature isn't enabled
 * @returns {Function} Returns a selector function that accepts state and returns a new function
 *
 * @example
 * import { selectCalculateScoreFunction } from 'selectProvider.js';
 *
 * // in component
 * const calcScoreFn = useSelector(selectCalculateScoreFunction);
 * const overallScore = calcScoreFn(myProvider);
 *
 * // in a thunk
 * const state = getState();
 * const calcScoreFn = selectCalculateScoreFunction(state);
 * const score = calcScore(myProvider)
 * */
export const selectCalculateScoreFunction = createSelector(
  [selectFeatureFlags.showDrScore],
  (showScores) =>
    function (provider) {
      if (!showScores || !provider) return null;

      if (!provider.scoreOverallQuality) return null;
      return { label: 'Quality', value: provider.scoreOverallQuality, tier: provider.scoreTier };
    }
);

/** This runs the calculateScoreFunction with the given provider
 * @returns {Function} A function that accepts state and will return null or a score object
 * @example
 * const scoreObject = useSelector(selectProvider(123).score);
 * console.log(scoreObject) // { label: 'Overall', value: 87 }
 */
export function score(id) {
  return createSelector(
    [providerById(id), selectCalculateScoreFunction],
    (provider, calcScoreFn) => {
      if (!provider) return null;
      return calcScoreFn(provider);
    }
  );
}

function isClientFeatured(id) {
  return createSelector(
    [providerById(id), selectFeatureFlags.showClientFeatured, selectContent.featuredProviderText],
    (provider, showClientFeatured, featuredProviderText) => {
      if (!provider) return null;
      if (!showClientFeatured) return false; // cannot be featured if the feature is disabled
      if (!featuredProviderText) return false; // cannot be featured if there is no global text to show

      return Boolean(provider.clientFeatured);
    }
  );
}

/** @returns {Function} */
function sortedProviderLocations(id) {
  return createSelector(
    [
      providerById(id),
      selectLocation.latLong,
      selectFeatureFlags.showClientFeatured,
      isClientFeatured(id),
      selectFeatureFlags.isSurgeryPlusNetwork,
      selectContent.surgeryPlusPhoneNumber,
    ],
    (
      provider,
      userLocation,
      showClientFeatured,
      isClientFeatured,
      isSurgeryPlusNetwork,
      surgeryPlusPhoneNumber
    ) => {
      if (!provider) return '';
      // Make a copy of the places to use in the sort function and possibly override all phone numbers
      // with a configured surgeryPlusPhoneNUmber if all requirements are met.
      const copiedPlaces = provider.places.map((place) => {
        if (
          isClientFeatured &&
          showClientFeatured &&
          isSurgeryPlusNetwork &&
          Boolean(surgeryPlusPhoneNumber)
        ) {
          return { ...place, phone: surgeryPlusPhoneNumber };
        }
        return place;
      });

      return copiedPlaces.sort(
        (a, b) =>
          distance(userLocation.latitude, userLocation.longitude, a.latitude, a.longitude) -
          distance(userLocation.latitude, userLocation.longitude, b.latitude, b.longitude)
      );
    }
  );
}

/*
 * Returns the closest location to the coordinates that were searched.
 * @returns {Function} */
function closestLocation(id) {
  return createSelector(
    [
      providerById(id),
      selectResults.coordinates,
      selectLocation.latLong,
      sortedProviderLocations(id),
    ],
    (provider, locationSearched, userLocation, sortedProviderLocations) => {
      if (!provider) return undefined;

      const { closestVisibleLocation } = provider;

      // if the server already calculated this property, return it (this happens on results received from a share route)
      if (closestVisibleLocation) {
        /* Legacy share links may include this closestVisibleLocation property.
        This would have been the closestVisibleLocation property that was previously added to providers on the front end.
        In order to support legacy share links, if this property exists on a provider we should consider it to be the closest visible location.
        Any newly created shares will NOT have this property and therefore this block would not be executed.
  
        @TODO TECH-3457
        This if block should be deleted 60 days after the Modern Experience release date. By that time, any legacy share link that was created will have expired.
        */
        const distanceFromUserLocation = +distance(
          closestVisibleLocation.latitude,
          closestVisibleLocation.longitude,
          userLocation.latitude,
          userLocation.longitude
        );

        return { ...closestVisibleLocation, distance: distanceFromUserLocation };
      }

      const closestLocation = sortedProviderLocations[0];
      const distanceFromUser = distance(
        locationSearched.latitude,
        locationSearched.longitude,
        closestLocation.latitude,
        closestLocation.longitude
      );

      return {
        ...closestLocation,
        distance: distanceFromUser,
      };
    }
  );
}

function clientFeaturedPill(id) {
  return createSelector(
    [isClientFeatured(id), selectContent.featuredProviderText],
    (isClientFeatured, clientFeaturedContent) => {
      if (!isClientFeatured) return null;
      if (!clientFeaturedContent) return null;

      const { banner, tooltip, color, icon, link } = clientFeaturedContent;
      const props = {
        label: banner,
        color,
        iconUrl: icon,
        TooltipProps: {
          message: tooltip,
          ...(link ? { href: { to: link, label: 'More Info Here' } } : {}),
        },
      };

      return props;
    }
  );
}

/** This is the pill utilize by Walmart to indicate high performing. It relies on the SHOW_HIGH_PERFORMING_RIBBON config value and the highlyRated field on the provider */
export function highPerformingPill(id) {
  return createSelector(
    [providerById(id), selectFeatureFlags.showHighPerformingRibbon],
    (provider, showHighPerforming) => {
      if (!showHighPerforming) return null;
      if (!provider?.highPerforming) return null;

      return {
        label: HIGH_PERFORMING_TITLE,
        TooltipProps: { message: HIGH_PERFORMING_TOOLTIP_TEXT },
        color: 'info.main',
        icon: <VerifiedUserIcon />,
      };
    }
  );
}

export function benefitDiffPill(id) {
  return createSelector(
    [
      providerById(id),
      selectContent.benefitDiffCopy,
      selectContent.benefitDiffTooltipTitle,
      selectContent.benefitDiffTooltipDescription,
      selectContent.falseBenefitDiffCopy,
      selectContent.falseBenefitDiffTooltipTitle,
      selectContent.falseBenefitDiffTooltipDescription,
    ],
    (
      provider,
      benefitDiffCopy,
      benefitDiffTooltipTitle,
      benefitDiffTooltipDescription,
      falseBenefitDiffCopy,
      falseBenefitDiffTooltipTitle,
      falseBenefitDiffTooltipDescription
    ) => {
      if (!provider) return null;

      if (!provider.hasBenefitDiff) {
        return falseBenefitDiffCopy
          ? {
              label: falseBenefitDiffCopy,
              color: 'warning.main',
              icon: <MonetizationOnIcon />,
              TooltipProps: {
                title: falseBenefitDiffTooltipTitle,
                message: falseBenefitDiffTooltipDescription,
              },
            }
          : null;
      }

      return benefitDiffCopy
        ? {
            label: benefitDiffCopy,
            color: 'info.main',
            icon: <MonetizationOnIcon />,
            TooltipProps: {
              title: benefitDiffTooltipTitle,
              message: benefitDiffTooltipDescription,
            },
          }
        : null;
    }
  );
}

// 0 = nothing
// 1 = exceeds standards
// 2 = meets standards
// 3 = below standards
// feat flagged by show_embold_recc
export function getScoreTierInfo(scoreTier, clientName) {
  if (scoreTier === SCORE_TIER_UNSCORED) return null;

  const Icon = SCORE_TIER_ICON_COMPONENTS[scoreTier];
  const scoreTierInfo = {
    color: 'scoreTier.3', // color for score tier of 3
    text: SCORE_TIER_LANGUAGE[scoreTier],
    icon: <Icon />,
    description:
      'Based on a clinically validated ranking system, this provider is less likely to provide clinically appropriate care, achieves worse outcomes for their patients, or is more likely to perform unnecessary and expensive services.',
  };

  if (scoreTier === SCORE_TIER_MIDDLE) {
    scoreTierInfo.color = 'scoreTier.2'; // color for score tier of 2
    scoreTierInfo.description =
      'Based on a clinically validated ranking system, this provider typically provides clinically appropriate care, achieves average outcomes for their patients, and limits unnecessary and expensive services.';
    // @TODO: implement client-agnostic score-tier styling
    if (clientName === SOCT || clientName === SOCT_PROVIDER_LOOKUP) {
      scoreTierInfo.inverted = true;
    }
  }
  if (scoreTier === SCORE_TIER_HIGH) {
    scoreTierInfo.color = 'scoreTier.1'; // color for score tier of 1
    scoreTierInfo.description =
      'Based on a clinically validated ranking system, this provider is more likely to provide clinically appropriate care, achieves better outcomes for their patients, and is less likely to perform unnecessary and expensive services.';
    // @TODO: implement client-agnostic score-tier styling
    if (clientName === SOCT || clientName === SOCT_PROVIDER_LOOKUP) {
      scoreTierInfo.color = 'primary.main';
    }
  }

  return { ...scoreTierInfo, scoreTier };
}

/**
 * This selector accepts state and returns a function that can be used to calculate a dynamic provider score tier or return null if the feature isn't enabled
 * @returns {Function} Returns a selector function that accepts state and returns a new function
 *
 * @example
 * import { selectCalculateScoreFunction } from 'selectProvider.js';
 *
 * // in component
 * const calcScoreTierFn = useSelector(selectCalculateScoreTierFunction);
 * const overallScoreTier = calcScoreTierFn(myProvider);
 *
 * // in a thunk
 * const state = getState();
 * const calcScoreTierFn = selectCalculateScoreTierFunction(state);
 * const score = calcScoreTier(myProvider)
 * */
export const selectCalculateScoreTier = createSelector(
  [selectFeatureFlags.showEmboldRecommended, selectClient.name],
  (showEmboldRecommended, clientName) =>
    function (provider) {
      if (!showEmboldRecommended || !provider) return null;
      if (!provider.scoreTier) return null;
      return { label: 'Overall', ...getScoreTierInfo(provider.scoreTier, clientName) };
    }
);

/** This runs the calculateScoreFunction with the given provider
 * @returns {Function} A function that accepts state and will return null or a score object
 * @example
 * const scoreTierObject = useSelector(selectProvider(123).score);
 * console.log(scoreTierObject) // { label: 'Overall', color: 'warning', inverted: true, scoreTier: 1, value: '' }
 */
export function scoreTier(id) {
  return createSelector(
    [providerById(id), selectCalculateScoreTier],
    (provider, calcScoreTierFn) => {
      if (!provider) return null;
      return calcScoreTierFn(provider);
    }
  );
}

function scoreTierPill(id) {
  return createSelector(
    [scoreTier(id), selectFeatureFlags.showAboutPage],
    (scoreTier, showAboutPage) => {
      if (!scoreTier) return null;

      return {
        label: scoreTier.text, // "Meets Standards etc"
        TooltipProps: {
          title: scoreTier.text,
          message: scoreTier.description,
          ...(showAboutPage && {
            link: {
              to: `/about#about-${scoreTier.text.replaceAll(' ', '-').toLowerCase()}`,
              label: 'Learn More',
            },
          }),
        },
        inverted: scoreTier.inverted,
        color: scoreTier.color,
        icon: scoreTier.icon,
      };
    }
  );
}

function scorePill(id) {
  return createSelector([score(id), providerById(id)], (score, provider) => {
    if (!score || !provider.scoreTier) return null;

    const color = SCORE_COLORS_BY_TIER[provider.scoreTier]; // color for score tier of 3

    return {
      color: color,
      label: `Score: ${score.value}`, // "Score: 97"
      TooltipProps: {
        title: `${score.label} Score:`, // "Geriatrics Score:"
        message:
          'Embold measures provider performance across a range of clinical measures. Higher scores indicate higher performance on these measures.',
      },
    };
  });
}

function pillList(id) {
  return createSelector(
    [
      scoreTierPill(id),
      scorePill(id),
      clientFeaturedPill(id),
      highPerformingPill(id),
      benefitDiffPill(id),
    ],
    (...pills) => {
      const pillList = pills.filter((pill) => Boolean(pill)); // some pills may return null, filter the null pills out
      // add a key property to each pill object
      for (let i = 0; i < pillList.length; i += 1) {
        pillList[i].key = `${id}-pill-${i}`;
      }
      return pillList;
    }
  );
}

function scoreTierBadge(id) {
  return createSelector([scoreTierPill(id)], (pillProps) => {
    if (!pillProps) return null;
    return pillProps;
  });
}

function scoreBadge(id) {
  return createSelector([scorePill(id), score(id)], (pillProps, score) => {
    if (!pillProps) return null;
    return {
      ...pillProps,
      // override the label and icon in the case of the score badge
      // with the score badge, we need the value of the score to appear as the icon
      // but the score pill does not have an icon
      label: `${score.label} Score`,
      icon: score.value,
    };
  });
}

function clientFeaturedBadge(id) {
  return createSelector([clientFeaturedPill(id)], (pillProps) => {
    if (!pillProps) return null;
    return pillProps;
  });
}

/** Based on highPerforming Pill */
export function highPerformingBadge(id) {
  return createSelector([highPerformingPill(id)], (pillProps) => {
    if (!pillProps) return null;
    return pillProps;
  });
}

/** Based on benefitDiff pill */
export function benefitDiffBadge(id) {
  return createSelector([benefitDiffPill(id)], (pillProps) => {
    if (!pillProps) return null;
    return pillProps;
  });
}

function badgeList(id) {
  return createSelector(
    [
      scoreTierBadge(id),
      scoreBadge(id),
      clientFeaturedBadge(id),
      highPerformingBadge(id),
      benefitDiffBadge(id),
    ],
    (...badges) => {
      const badgeList = badges.filter((badge) => Boolean(badge)); // some badges may return null, filter the null badges out
      return badgeList;
    }
  );
}

const TOP_10_PERCENT_ADJECTIVE = 'excellent';
const TOP_25_PERCENT_ADJECTIVE = 'above average';

function diagnosingStrength(id) {
  return createSelector([providerById(id)], (provider) => {
    if (!provider) return null;

    const { diagnosingPercentile = 0 } = provider;
    if (diagnosingPercentile < PERCENTILE_THRESHOLD) return null;

    const result = {
      label: 'Diagnosing',
      percentile: diagnosingPercentile,
      preface: 'This provider is',
      adjective: diagnosingPercentile >= 0.9 ? TOP_10_PERCENT_ADJECTIVE : TOP_25_PERCENT_ADJECTIVE,
      categoryLanguage: 'at using the proper tests and procedures for diagnosis and screening.',
      supplementalExplanatoryLanguage:
        'Diagnosing may include completing more screenings and tests according to guidelines, as well as ordering fewer unnecessary tests.',
    };

    result.fullText = `${result.preface} ${result.adjective} ${result.categoryLanguage}`;

    return result;
  });
}
function treatmentStrength(id) {
  return createSelector([providerById(id)], (provider) => {
    if (!provider) return null;

    const { treatmentPercentile = 0 } = provider;
    if (treatmentPercentile < PERCENTILE_THRESHOLD) return null;

    const result = {
      label: 'Treatment',
      percentile: treatmentPercentile,
      preface: 'This provider is',
      adjective: treatmentPercentile >= 0.9 ? TOP_10_PERCENT_ADJECTIVE : TOP_25_PERCENT_ADJECTIVE,
      categoryLanguage: 'at providing effective patient-centered treatment, care, and management.',
      supplementalExplanatoryLanguage:
        'Treatment may include achieving good medication adherence rates, appropriately monitoring patients’ health status, and providing guideline recommended care.',
    };

    result.fullText = `${result.preface} ${result.adjective} ${result.categoryLanguage}`;

    return result;
  });
}

function outcomesStrength(id) {
  return createSelector([providerById(id)], (provider) => {
    if (!provider) return null;

    const { outcomesPercentile = 0 } = provider;
    if (outcomesPercentile < PERCENTILE_THRESHOLD) return null;

    const result = {
      label: 'Patient Outcomes',
      percentile: outcomesPercentile,
      preface: 'This provider achieves',
      adjective: outcomesPercentile >= 0.9 ? TOP_10_PERCENT_ADJECTIVE : TOP_25_PERCENT_ADJECTIVE,
      categoryLanguage: 'outcomes for their patients',
      supplementalExplanatoryLanguage:
        'Patient Outcomes may include reducing procedure-related complications, lowering ED visits or hospitalizations, and better managing chronic conditions.',
    };

    result.fullText = `${result.preface} ${result.adjective} ${result.categoryLanguage}`;

    return result;
  });
}

/**
 @typedef SortedAffiliations
 @type {Object}
 @property {{ label: string, isPrimary: true, type: string }} [primaryAffiliation]
 @property {{ label: string, isPrimary: boolean }[]} groupAffiliations
 @property {{ label: string, isPrimary: boolean }[]} hospitalAffiliations
 */

/**
 * Get sorted affiliations with primary affiliation marked within the array it came from (groupAffiliation or providerAffiliations)
 * Also return a primaryAffiliation object which dictates the array the primary affiliation was found in
 * @param {Object} provider provider object
 * @param {string} provider.affiliation string value, should match one of the values in the next two arrays
 * @param {string[]} provider.groupAffiliations
 * @param {string[]} provider.hospitalAffiliations
 *
 * @returns {SortedAffiliations}
 */
export function sortedAffiliations(id) {
  return createSelector(
    [providerById(id)],
    ({
      affiliation: primaryAffiliationLabel,
      groupAffiliations: groupAffiliationLabels,
      hospitalAffiliations: hospitalAffiliationLabels,
    }) => {
      // if no primary affiliation, still convert to array of objects
      const toObject = (label) => ({ label, isPrimary: false });
      if (!primaryAffiliationLabel) {
        return {
          primaryAffiliation: null,
          groupAffiliations: groupAffiliationLabels?.map(toObject) || [],
          hospitalAffiliations: hospitalAffiliationLabels?.map(toObject) || [],
        };
      }

      // create separate primaryAffiliation object
      // mark primary affiliation within affiliation arrays, sort to front
      const primaryAffiliation = { label: primaryAffiliationLabel, isPrimary: true };
      const setPrimary = (label, type) => {
        const isPrimary = label === primaryAffiliationLabel;
        if (isPrimary) primaryAffiliation.type = type;
        return { label, isPrimary };
      };
      const trueFirst = (a, b) => Number(b?.isPrimary) - Number(a?.isPrimary);
      const groupAffiliations =
        groupAffiliationLabels
          ?.map((label) => setPrimary(label, AFFILIATION_TYPES.GROUP))
          .sort(trueFirst) || [];
      const hospitalAffiliations =
        hospitalAffiliationLabels
          ?.map((label) => setPrimary(label, AFFILIATION_TYPES.HOSPITAL))
          .sort(trueFirst) || [];

      return {
        primaryAffiliation,
        groupAffiliations,
        hospitalAffiliations,
      };
    }
  );
}

function providerStrengths(id) {
  return createSelector(
    [diagnosingStrength(id), treatmentStrength(id), outcomesStrength(id)],
    (...strengths) => strengths.filter((strength) => Boolean(strength)) // take all input strengths, filter out null values
  );
}

function subspecialtiesAsString(id) {
  return createSelector([providerById(id)], (provider) => {
    if (!provider) return '';

    const { subspecialties = [] } = provider;
    return subspecialties.map((ss) => ss.subspecialtyName).join(', ');
  });
}

function costText(id) {
  return createSelector([providerById(id), selectFeatureFlags.showCost], (provider, enableCost) => {
    if (!provider || !enableCost) return null;

    const { scoreCareJourneyCost } = provider;

    return COST_EFFICIENCY_MAP[scoreCareJourneyCost] || null;
  });
}

function showBenefitDecrease(id) {
  return createSelector(
    [providerById(id), selectNetworks.currentSlug, selectContent.isTodayInBenefitChangeDateRange],
    (provider, networkSlug, inDateRange) => {
      const nwa = networkSlug === 'bcbsarkansas';
      const { benefitDecrease } = provider;
      // never display benefits changing UI for northwest arkansas network
      if (nwa) return false;
      // only show benefits changing UI on flagged providers
      if (!benefitDecrease) return false;
      // only show benefits changing UI when today is within the date range in the client config
      if (!inDateRange) return false;
      return true;
    }
  );
}

/**
 * @param {string} id Provider entityId
 * @returns {string} A string representation of the provider object used for copy and paste feature
 */
export function getProviderString(id) {
  return createSelector(
    [
      // provider data
      providerById(id),
      fullNameAndTitle(id),
      closestLocation(id),
      score(id),
      networkContent(id),
      costText(id),
      diagnosingStrength(id),
      treatmentStrength(id),
      outcomesStrength(id),
      // feature flags
      selectFeatureFlags.showDrScore,
      selectFeatureFlags.showProviderStrengths,
      selectFeatureFlags.showCost,
      selectFeatureFlags.showSubspecialties,
      // other
      selectResults.wasPlaceSearch,
    ],
    (
      // provider data
      provider,
      fullNameAndTitle,
      closestLocation,
      score,
      networkContent,
      costText,
      diagnosingStrength,
      treatmentStrength,
      outcomesStrength,
      // feature flags
      showDrScore,
      showProviderStrengths,
      showCost,
      showSubspecialties,
      // other
      wasPlaceSearch
    ) => {
      if (wasPlaceSearch) return null;
      const NA = 'N/A'; // constant used when no value is available
      let result = ''; // complete string to be returned at the end

      const appendLine = (text) => {
        result += '\n'.concat(text.replace(/\w+/g, capitalize)); // captalize the first letter of each word in string
      };
      const appendHeader = (text) => {
        appendLine(`--${text}--`);
      };

      // name & title
      result = `Name: ${fullNameAndTitle}`;

      // OVERVIEW SECTION
      appendHeader('Overview');

      // NETWORK CONTENT
      appendLine(`Network Coverage: ${networkContent.networkText}`);

      // location details, all fields in this if block are dependant on this closestVisibleLocation object
      if (closestLocation) {
        const {
          address1,
          address2 = '',
          city,
          state,
          zipcode,
          phone,
          acceptingNewPatients,
        } = closestLocation;

        // address
        if (address1 && city && state) {
          appendLine(`Address: ${address1}`); // Address: 123 Main St
          if (address2) result += ` ${address2}`; // Address: 123 Main St Ste 456
          result += `, ${city}, ${state} ${zipcode}`; // Address: 123 Main St Ste 456, Chicago, IL 12345
        }

        // phone
        appendLine(`Phone: ${phone || NA}`);

        // distance
        let distance;

        if (closestLocation.distance) {
          distance = closestLocation.distance;
          distance += ' mile';
          if (distance !== 1.0) distance += 's';
        } else {
          distance = NA;
        }

        appendLine(`Distance: ${distance}`);

        // accepting new patients
        switch (acceptingNewPatients) {
          case true:
            appendLine(`Accepting New Patients: Yes`);
            break;
          case false:
            appendLine(`Accepting New Patients: No`);
            break;
          default:
            appendLine(`Accepting New Patients: ${NA}`);
        }
      }

      // gender
      appendLine(`Gender: ${provider.gender || NA}`);

      // EMBOLD SCORES SECTION
      if (showDrScore || showProviderStrengths || showSubspecialties) {
        appendHeader('Embold Measured Areas');
      }

      if (score && showDrScore) appendLine(`${score.label}: ${score.value}`);

      if (showProviderStrengths) {
        appendLine(`Diagnosing Quality: ${diagnosingStrength ? diagnosingStrength.adjective : NA}`);

        appendLine(
          `Treatment Plan Quality: ${treatmentStrength ? treatmentStrength.adjective : NA}`
        );

        appendLine(
          `Patient Outcomes Quality: ${outcomesStrength ? outcomesStrength.adjective : NA}`
        );
      }

      // specialty areas
      if (showSubspecialties && provider.subspecialties?.length) {
        let subspecialtiesString = '';
        for (let i = 0; i < provider.subspecialties.length; i += 1) {
          subspecialtiesString += provider.subspecialties[i].subspecialtyName;
          if (i < provider.subspecialties.length - 1) subspecialtiesString += ', ';
        }

        appendLine(`Specialty Areas: ${subspecialtiesString}`);
      }

      // cost
      if (showCost) {
        appendLine(`Cost: ${costText || NA}`);
      }

      // CREDENTIALS AND EXPERIENCE SECTION
      appendHeader('Credentials & Experience');

      // medical school
      appendLine(`Medical School: ${provider.medicalSchool || NA}`);
      // years experience
      appendLine(`Years Experience: ${provider.yearsExperience || NA}`);

      return result;
    }
  );
}

export function getProvidersString(ids) {
  const providerStrings = ids.map((id) => getProviderString(id));
  return createSelector(
    [selectResults.wasPlaceSearch, ...providerStrings],
    (wasPlaceSearch, ...providerStrings) => {
      if (wasPlaceSearch) return null;
      let result = '';
      if (providerStrings?.length) result += providerStrings.join('\n\n');
      return result;
    }
  );
}

function sortedSubspecialties(id) {
  return createSelector(
    [providerById(id), selectResults.subspecialtyId],
    (provider, searchedSubspecialtyId = null) => {
      if (!provider) return [];
      const { subspecialties = [] } = provider;
      if (!Array.isArray(subspecialties)) return [];

      const sortMap = {
        // will sort lowest value to highest value
        [SUBSPECIALTY_TYPES.SCORED]: 1,
        [SUBSPECIALTY_TYPES.HIGH_VOLUME]: 2,
        [SUBSPECIALTY_TYPES.SELF_IDENTIFIED]: 3,
      };

      const copiedSubspecialties = [...subspecialties];
      return copiedSubspecialties.sort((a, b) => {
        if (searchedSubspecialtyId) {
          // first sort the matching subspecialty to the front of the list if we have a searched subspecialty id
          if (searchedSubspecialtyId === a.subspecialtyId) return -1;
          if (searchedSubspecialtyId === b.subspecialtyId) return 1;
        }

        // second, sort by subspecialtyType
        const aSortValue = sortMap[a.subspecialtyType] || Infinity;
        const bSortValue = sortMap[b.subspecialtyType] || Infinity;
        return aSortValue - bSortValue;
      });
    }
  );
}

function isPreferredGroup(id) {
  return createSelector([providerById(id)], (provider) => provider?.groupScoreTier === 1);
}

/* ************************************************ */
/* *************** Master Function **************** */
/* ************************************************ */

/**
 * This master selectProvider function accepts an entityId/npi string and builds selectors for that given id.
 * Every selector needs to be passed into useSelector or given the argument of state to return a value.
 * @param {string} id
 *
 * @example
 * // in a component
 * const fullName = useSelector(selectProvider('abc').fullName);
 *
 * // in a thunk
 * const state = getState();
 * const fullName = selectProvider('123').fullName(state);
 */
function selectProvider(id) {
  // return an object of selectors
  return {
    data: providerById(id),
    fullNameAndTitle: fullNameAndTitle(id),
    // distanceToNearestLocation: distanceToNearestLocation(id),
    sortedProviderLocations: sortedProviderLocations(id),
    closestLocation: closestLocation(id),
    numberOfLocationsAcceptingNewPatients: numberOfLocationsAcceptingNewPatients(id),
    hasTelehealthAvailable: hasTelehealthAvailable(id),
    tierDesignationRollup: tierDesignationRollup(id),
    networkContent: networkContent(id),
    calculateScoreFunction: selectCalculateScoreFunction, // this selector is the same for every provider but it's added to this object for developer convenience
    score: score(id),
    scoreTier: scoreTier(id),
    isClientFeatured: isClientFeatured(id),
    pillList: pillList(id),
    badgeList: badgeList(id),
    scorePill: scorePill(id),
    providerStrengths: providerStrengths(id),
    diagnosingStrength: diagnosingStrength(id),
    treatmentStrength: treatmentStrength(id),
    outcomesStrength: outcomesStrength(id),
    subspecialtiesAsString: subspecialtiesAsString(id),
    costText: costText(id),
    showBenefitDecrease: showBenefitDecrease(id),
    sortedSubspecialties: sortedSubspecialties(id),
    isPcp: isPcp(id),
    sortedAffiliations: sortedAffiliations(id),
    isPreferredGroup: isPreferredGroup(id),
  };
}

// this ensures that when we call select.provider('abc'), it generates n number of selectors for us. But calling select.provider('abc') again does not rebuild the exact same n number of selectors
export default memoize(selectProvider);
