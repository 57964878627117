/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useRef } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import PropTypes, { RefType } from 'propTypes/index';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Typography,
  Grid,
  Link as MuiLink,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronRight from '@material-ui/icons/ChevronRight';

import AppBanner from 'components/Banner/AppBanner';
import useFocusAnchors from 'utils/FocusRefContext';
import { CHOOSE_NETWORK_TEXT } from 'utils/constants';
import useStartOver from 'hooks/useStartOver';
import useIsSharePage from 'hooks/useIsSharePage';
import useLogout from 'hooks/useLogout';
import { actions, select } from 'store/toolkit';
import { VirtualAssistant } from 'icons/index';

import UpdateNetworkForm from './Forms/NetworkSelects/UpdateNetworkForm';
import HeaderSkipLinks from './a11y/HeaderSkipLinks';
import { SUPPORT_ROUTE } from '../navigation/navigationConstants'; // for some reason eslint can't resolve an absolute path to navigation
import HamburgerFooter from './HamburgerFooter';

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: 10,
    color: theme.palette.text.primary,
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
    [theme.breakpoints.down('md')]: {
      zIndex: 1300,
    },
  },
  headerLogo: {
    '& > img': { height: 30 },
    '&.Mui-focusVisible': {
      outline: 'none',
      '& > img': {
        // for firefox and safari we need to outline the inner img tag rather than the a tag
        outline: `2px solid ${theme.palette.focusIndicator}`,
        outlineOffset: 2,
      },
    },
  },
  headerWrap: {
    margin: 0,
    textAlign: 'center',
    fontSize: 0,
    [theme.breakpoints.up('md')]: {
      textAlign: 'left',
    },
  },
  '@media screen and (-ms-high-contrast: black-on-white)': {
    headerLogo: {
      background: theme.palette.primary.main,
      height: 40,
      padding: 5,
      marginLeft: -5,
    },
  },
  '@media (forced-colors: active) and (prefers-color-scheme: light)': {
    headerWrap: {
      forcedColorAdjust: 'none',
      background: 'black',
    },
  },
  menuButton: {
    position: 'absolute',
    right: '0.25rem',
  },
  headerNavWrap: { gap: 35 },
  listRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '& span': {
      fontWeight: 700,
    },
    '& div': {
      margin: 0,
    },
  },
  listItemRoot: {
    display: 'inline-block',
    width: 'auto',
    padding: '0 35px 0 16px',
    '&:last-child': {
      paddingRight: 0,
    },
  },
  linkWithCaret: {
    padding: '0 4px',
    display: 'inline-flex',
    alignItems: 'center',
  },
  iconRoot: {
    marginLeft: 10,
    fontSize: 12,
  },
  closeMobile: {}, // need to define this key in order to use it below
  mobileMenu: {
    zIndex: '1500',
    '& li': {
      paddingTop: 8,
      paddingBottom: 8,
    },
    '& a, & button': {
      padding: 0,
    },
    '& $closeMobile': {
      position: 'absolute',
      top: 0,
      right: 0,
      color: theme.palette.grey[700],
      padding: 12,
      zIndex: 1,
    },
    '& .MuiPaper-root:focus': {
      outline: `2px solid ${theme.palette.secondary.main}`,
    },
    '& .MuiListItemIcon-root': {
      minWidth: 32,
    },
  },
  chatButton: {
    display: 'flex',
    alignItems: 'center',
    columnGap: theme.spacing(1),
  },
}));

function LogoLinkWrap({ children, clickable, startOver, logoRef, ...props }) {
  return clickable ? (
    <Link
      component={RouterLink}
      to="/"
      innerRef={logoRef}
      // we're passing null to startOver because this fn is being called from a link, which is already pushing to the location history
      onClick={() => startOver(null)}
      {...props}
    >
      {children}
    </Link>
  ) : (
    children
  );
}

LogoLinkWrap.propTypes = {
  startOver: PropTypes.func.isRequired,
  clickable: PropTypes.bool.isRequired,
  logoRef: RefType.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

/**
 * ---- Adding links to the header ----
 *
 * 1. External links - use the extended Mui Link component
 *       <Link>...</Link>
 * 2. Internal route - use react-router Link component and pass Mui Link for styles
 *       <Link component={RouterLink}>...</Link>
 * 3. Action button - use Mui Link component and pass "button" for semantics
 *       <Link component="button">...</Link>
 */

function Link(props) {
  return <MuiLink {...props} underline="hover" color="primary" />;
}

function Header({ isMobile, showChatButton }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const focusAnchors = useFocusAnchors();
  const [menuOpen, setMenuOpen] = useState(false);
  const isLoggedIn = useSelector(select.auth.isLoggedIn);
  const networkSlug = useSelector(select.networks.currentSlug);
  const clientName = useSelector(select.client.displayName);
  const isSharePage = useIsSharePage();

  const closeButtonRef = useRef(null);

  const isAuthenticated = isLoggedIn && !isSharePage;

  const reduxConfigLoading = useSelector(select.config.isLoading);
  const hideSupport = useSelector(select.featureFlags.hideSupport);
  const isWhitelabeled = useSelector(select.featureFlags.isWhitelabeled);
  const appName = useSelector(select.content.appName);
  const showRegionSelector = useSelector(select.featureFlags.showRegionSelector);

  const schedulePhoneNumber = useSelector(select.content.schedulePhoneNumber);
  const memberIdLink = useSelector(select.content.memberIdLink);
  const showBcbsLink = useSelector(select.featureFlags.showBcbsLink);
  const headerImage = useSelector(select.client.headerImage);
  const showDisclaimer = useSelector(select.featureFlags.showDisclaimer);
  const loadingClientConfig = useSelector(select.config.isLoading);
  const showNetworkLogout = useSelector(select.featureFlags.showNetworkLogoutButton);
  const logoutText = 'Change your network';

  const startOver = useStartOver();
  const handleLogout = useLogout();

  const openMenu = () => {
    setMenuOpen(true);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const resetSearch = () => {
    // we're passing null to startOver because this fn is being called from a link, which is already pushing to the location history
    startOver(null);
    closeMenu();
  };

  const handleCall = (e) => {
    e.stopPropagation();
    document.location.href = `tel:${schedulePhoneNumber}`;
  };
  const handleFeedback = () => {
    dispatch(actions.ui.openProfileModal('feedback'));
    closeMenu();
  };
  const handleChatModal = () => {
    dispatch(actions.ui.openModal('chat'));
    closeMenu();
  };

  const isCurrentPage = (slug) => window.location.pathname.includes(slug);
  const logoAltText = `${clientName} ${appName} logo. Click to go back to home`;

  return (
    <AppBar position="sticky" color="inherit" className={classes.appBar} id="header" elevation={0}>
      <AppBanner aria-live="polite" showChatButton={showChatButton} />
      <Toolbar>
        <div className={classes.headerWrap}>
          <Typography component="h1" variant="srOnly">
            {`${clientName} ${appName}`}
          </Typography>
          <HeaderSkipLinks showChatLink={showChatButton} handleChatModal={handleChatModal} />
          <LogoLinkWrap
            clickable
            startOver={startOver}
            logoRef={focusAnchors.logo}
            className={classes.headerLogo}
          >
            <img src={headerImage} alt={logoAltText} />
          </LogoLinkWrap>
        </div>

        {!isMobile ? (
          <Grid container justifyContent="flex-end" classes={{ root: classes.headerNavWrap }}>
            <nav aria-label="Primary navigation">
              <List classes={{ root: classes.listRoot }}>
                {isAuthenticated && !loadingClientConfig && schedulePhoneNumber && (
                  <ListItem classes={{ root: classes.listItemRoot }}>
                    <Link
                      component="button"
                      onClick={handleCall}
                      classes={{ root: classes.linkWithCaret }}
                    >
                      <ListItemText primary={`Need Help? ${schedulePhoneNumber}`} />
                      <ChevronRight className={classes.iconRoot} />
                    </Link>
                  </ListItem>
                )}

                {isAuthenticated && memberIdLink && (
                  <ListItem classes={{ root: classes.listItemRoot }}>
                    <Link href={memberIdLink} classes={{ root: classes.linkWithCaret }}>
                      <ListItemText primary="Member ID Card" />
                      <ChevronRight className={classes.iconRoot} />
                    </Link>
                  </ListItem>
                )}

                {isAuthenticated && !loadingClientConfig && !hideSupport && (
                  <ListItem classes={{ root: classes.listItemRoot }}>
                    <Link
                      component={RouterLink}
                      to={SUPPORT_ROUTE}
                      classes={{ root: classes.linkWithCaret }}
                    >
                      <ListItemText primary="Support" />
                      <ChevronRight className={classes.iconRoot} />
                    </Link>
                  </ListItem>
                )}
                {isAuthenticated && showNetworkLogout && (
                  <ListItem classes={{ root: classes.listItemRoot }}>
                    <Link
                      component="button"
                      onClick={handleLogout}
                      classes={{ root: classes.linkWithCaret }}
                    >
                      <ListItemText primary={logoutText} />
                      <ChevronRight className={classes.iconRoot} />
                    </Link>
                  </ListItem>
                )}
              </List>
            </nav>
            {!reduxConfigLoading && <UpdateNetworkForm />}
          </Grid>
        ) : (
          <>
            <IconButton
              aria-label="Open navigation menu"
              className={classes.menuButton}
              onClick={openMenu}
            >
              <MenuIcon alt="Navigation menu" color="primary" />
            </IconButton>

            <Drawer
              anchor="top"
              open={menuOpen && isMobile}
              onClose={closeMenu}
              className={classes.mobileMenu}
              SlideProps={{ onEntered: () => closeButtonRef.current?.focus() }}
            >
              <nav aria-label="Primary navigation">
                <IconButton
                  ref={closeButtonRef}
                  aria-label="close"
                  onClick={closeMenu}
                  className={classes.closeMobile}
                >
                  <CloseIcon />
                </IconButton>
                <List>
                  <ListItem>
                    <Link component={RouterLink} to="/" onClick={resetSearch}>
                      <ListItemText primary="Home" />
                    </Link>
                  </ListItem>

                  {isAuthenticated && showNetworkLogout && (
                    <ListItem>
                      <Link component="button" onClick={handleLogout}>
                        <ListItemText primary={logoutText} />
                      </Link>
                    </ListItem>
                  )}

                  {isAuthenticated && !loadingClientConfig && schedulePhoneNumber && (
                    <ListItem>
                      <Link component="button" onClick={handleCall}>
                        <ListItemText primary={`Need Help? ${schedulePhoneNumber}`} />
                      </Link>
                    </ListItem>
                  )}
                  {isAuthenticated && memberIdLink && networkSlug && (
                    <ListItem>
                      <Link href={memberIdLink}>
                        <ListItemText primary="Member ID Card" />
                      </Link>
                    </ListItem>
                  )}

                  {isAuthenticated && !loadingClientConfig && !hideSupport && (
                    <ListItem>
                      <Link component={RouterLink} to={SUPPORT_ROUTE} onClick={closeMenu}>
                        <ListItemText primary="Support" />
                      </Link>
                    </ListItem>
                  )}

                  {showBcbsLink && networkSlug && (
                    <ListItem>
                      <Link component={RouterLink} to="/contactbcbs" onClick={closeMenu}>
                        <ListItemText primary="Contact Blue Cross Blue Shield" />
                      </Link>
                    </ListItem>
                  )}

                  {!isCurrentPage('provider') && showRegionSelector && isAuthenticated && (
                    <ListItem>
                      <Link
                        component="button"
                        onClick={() => {
                          closeMenu();
                          dispatch(actions.ui.openModal('updateRegion'));
                        }}
                      >
                        <ListItemText primary={CHOOSE_NETWORK_TEXT} />
                      </Link>
                    </ListItem>
                  )}

                  <ListItem>
                    <Link component={RouterLink} onClick={closeMenu} to="/privacy">
                      <ListItemText primary="Privacy Statement" />
                    </Link>
                  </ListItem>

                  <ListItem>
                    <Link component={RouterLink} onClick={closeMenu} to="/terms">
                      <ListItemText primary="Terms of Use" />
                    </Link>
                  </ListItem>

                  {showDisclaimer && (
                    <ListItem>
                      <Link component={RouterLink} onClick={closeMenu} to="/disclaimer">
                        <ListItemText primary="Disclaimer" />
                      </Link>
                    </ListItem>
                  )}

                  <ListItem>
                    <Link component="button" onClick={handleFeedback}>
                      <ListItemText primary="Submit Feedback" />
                    </Link>
                  </ListItem>

                  {showChatButton && (
                    <ListItem>
                      <Link
                        component="button"
                        onClick={handleChatModal}
                        className={classes.chatButton}
                      >
                        <VirtualAssistant />
                        <ListItemText
                          primary={`${isWhitelabeled ? '' : 'Embold '}Virtual Assistant`}
                        />
                      </Link>
                    </ListItem>
                  )}
                </List>
                <HamburgerFooter />
              </nav>
            </Drawer>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  showChatButton: PropTypes.bool.isRequired,
};
export default React.memo(Header);
