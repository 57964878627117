/* eslint-disable no-shadow */
import { createSelector } from '@reduxjs/toolkit';
import { memoize } from 'lodash';
import { determineResultType, distance, logDevMessage, resultIsProviderType } from 'utils/utils';
import { isValidCoords } from 'utils/mapUtils';

import {
  specialtySuggestionMap,
  subspecialtySuggestionMap,
} from 'components/SearchSuggestion/searchSuggestionMap';
import { ENDPOINTS } from 'store/fusionServices/fusionConstants';
import { CARE_CATEGORIES, PLACE_RESULT_TYPE, PROVIDER_RESULT_TYPE } from 'utils/constants';
import { apiUrl as selectApiUrl, maxCompareCount } from '../config/selectConfig';
import * as selectNetworks from '../config/selectNetworks';
import * as selectLocation from '../location/selectLocation';
import { RESULTS_SLICE_NAME } from '../slicesNames';
import * as selectSpecialties from '../specialties/selectSpecialties';

/* ********** */
/* Root Level */
/* ********** */
export const isLoading = (state) => state[RESULTS_SLICE_NAME].isLoading;
export const isLoadingMore = (state) => state[RESULTS_SLICE_NAME].isLoadingMore; // Load More button in Providers list was clicked
export const error = (state) => state[RESULTS_SLICE_NAME].error;
export const lastRequestId = (state) => state[RESULTS_SLICE_NAME].lastRequestId;
export const selectedId = (state) => state[RESULTS_SLICE_NAME].selectedId;
export const clusterIds = (state) => state[RESULTS_SLICE_NAME].clusterIds;
export const hoveredId = (state) => state[RESULTS_SLICE_NAME].hoveredId;
// clusterMarkerId saves the id of the cluster marker, which is provided by Azure Maps
export const clusterMarkerId = (state) => state[RESULTS_SLICE_NAME].clusterMarkerId;
export const selectedFeatureCoords = (state) => state[RESULTS_SLICE_NAME].selectedFeatureCoords;
export const suggestedExpandedRadius = (state) => state[RESULTS_SLICE_NAME].suggestedExpandedRadius;

/* ****************** */
/* Response Selectors */
/* ****************** */
export const response = (state) => state[RESULTS_SLICE_NAME].response;
export const all = (state) => state[RESULTS_SLICE_NAME].response.results || {};
export const idList = (state) => state[RESULTS_SLICE_NAME].response.resultIdList || [];
export const count = (state) => state[RESULTS_SLICE_NAME].response.count || 0;
export const paginationLink = (state) => state[RESULTS_SLICE_NAME].response.next;
export const filterCounts = (state) => state[RESULTS_SLICE_NAME].response.filterCounts;
export const markdownNotification = (state) =>
  state[RESULTS_SLICE_NAME].response.markdownNotification;
export const shareType = (state) => state[RESULTS_SLICE_NAME].shareType;
export const asArray = createSelector([all, idList], (resultObj, ids) =>
  ids.map((id) => resultObj[id])
);
export function byId(id) {
  return createSelector([all], (resultsObj) => resultsObj[id]);
}

/** Returns the ordered array of result objects
 * @returns {object[]}
 */
export const list = createSelector([idList, all], (ids, results) => ids.map((id) => results[id]));

export const listLength = createSelector([idList], (ids) => ids.length);

export const paginationPathname = createSelector(
  [paginationLink, selectApiUrl],
  (fullUrl, baseUrl) => {
    if (!fullUrl) return '';

    // remove the base url from the pagination link to leave us with just the endpoint
    // example https://www.emboldhealth.com/api/pg/providers/123 => /providers/123
    return fullUrl.replace(baseUrl, '');
  }
);

export const resultsCoordsList = createSelector([all], (results) =>
  Object.keys(results).map((id) => ({
    latitude: results[id].places[0].latitude,
    longitude: results[id].places[0].longitude,
  }))
);

/* ***************** */
/* Request selectors */
/* ***************** */
export const request = (state) => state[RESULTS_SLICE_NAME].request;
export const url = (state) => state[RESULTS_SLICE_NAME].request?.url;
export const endpoint = (state) => state[RESULTS_SLICE_NAME].request?.endpoint || '';
export const filters = (state) => state[RESULTS_SLICE_NAME].request.filters;
export const search = (state) => state[RESULTS_SLICE_NAME].request?.search;
export const searchText = (state) => state[RESULTS_SLICE_NAME].request?.search?.text;
export const specialtyId = (state) => state[RESULTS_SLICE_NAME].request?.search?.specialtyId;
export const subspecialtyId = (state) => state[RESULTS_SLICE_NAME].request?.search?.subspecialtyId;
export const entityId = (state) => state[RESULTS_SLICE_NAME].request?.search?.entityId;
export const serviceId = (state) => state[RESULTS_SLICE_NAME].request?.search?.serviceId;
export const serviceType = (state) => state[RESULTS_SLICE_NAME].request?.search?.serviceType;
export const location = (state) => state[RESULTS_SLICE_NAME].request?.location;
export const boundingBox = (state) => state[RESULTS_SLICE_NAME].request?.location?.boundingBox;
export const northeastBound = (state) =>
  state[RESULTS_SLICE_NAME].request?.location?.boundingBox?.ne;
export const southwestBound = (state) =>
  state[RESULTS_SLICE_NAME].request?.location?.boundingBox?.sw;
export const searchRadius = (state) => state[RESULTS_SLICE_NAME].request?.location?.radius;
export const searchType = (state) => state[RESULTS_SLICE_NAME].request?.search?.type;
export const coordinates = (state) => state[RESULTS_SLICE_NAME].request?.location?.coordinates;
export const locationInput = (state) => state[RESULTS_SLICE_NAME].request?.location?.input;
export const city = (state) => state[RESULTS_SLICE_NAME].request?.location?.city;
export const state = (state) => state[RESULTS_SLICE_NAME].request?.location?.state;
export const ordering = (state) => state[RESULTS_SLICE_NAME].request?.ordering;
export const isSuggestionSelected = (state) =>
  state[RESULTS_SLICE_NAME].request?.isSuggestionSelected;

/** @returns {boolean} A loose place search suggests that we are searching for a place, but have not selected a specific place from autocomplete suggestions */
export const wasLoosePlaceSearch = createSelector(
  [searchType, isSuggestionSelected],
  (searchType, suggestionSelected) =>
    searchType === CARE_CATEGORIES.FACILITY_NAME && !suggestionSelected
);

/** @returns {boolean} True if doing a place by name search and autocomplete suggestion was chosen */
export const wasPlaceByExactNameSearch = createSelector(
  [searchType, wasLoosePlaceSearch],
  (searchType, isLoosePlaceSearch) =>
    searchType === CARE_CATEGORIES.FACILITY_NAME && !isLoosePlaceSearch
);

export const wasSingleResultSearch = createSelector([entityId], (entityId) => Boolean(entityId));
export const wasProviderSearch = createSelector([endpoint], (endpoint = '') =>
  endpoint.startsWith(ENDPOINTS.PROVIDERS)
);
export const wasPlaceSearch = createSelector([endpoint], (endpoint = '') =>
  endpoint.startsWith(ENDPOINTS.PLACES)
);
export const wasServiceSearch = createSelector([serviceId], (serviceId) => Boolean(serviceId));

export const isResultsListProviders = createSelector([idList, all], (ids, resultObj) => {
  if (!ids?.length) return false;

  const firstResult = resultObj[ids[0]];

  return resultIsProviderType(firstResult);
});

export const resultsType = createSelector([idList, all], (ids, resultObj) => {
  if (!ids?.length) return '';

  const firstResult = resultObj[ids[0]];

  return determineResultType(firstResult);
});

export function filterValueByKey(key) {
  return createSelector([filters], (filterObj = {}) => filterObj[key]);
}

export const returnedNoResults = createSelector(
  [url, count, isLoading, error],
  (url, count, isLoading, error) => Boolean(!isLoading && url && count === 0) || Boolean(error)
);

/* ********************** */
/* Compare List selectors */
/* ********************** */
export const compareListIds = (state) => state[RESULTS_SLICE_NAME].compareList;
export const enableCompareModal = createSelector(
  [compareListIds, idList, isLoading],
  (compareIds, resultIds, isLoading) =>
    Boolean(compareIds.length > 1 && compareIds.length <= resultIds.length && !isLoading)
);

export const isCompareListAboveOne = createSelector(
  [compareListIds],
  (compareIds) => compareIds.length > 1
);

export const comparedResults = createSelector([compareListIds, all], (comparedIds, results) =>
  comparedIds.map((id) => results[id])
);

export function isIdInCompareList(id) {
  return createSelector([compareListIds], (compareIds) => compareIds.includes(id));
}

export const compareListLength = createSelector(
  [compareListIds],
  (compareIds) => compareIds.length
);

export const isCompareListFull = createSelector(
  [compareListIds, maxCompareCount],
  (list, maxLength) => list.length >= maxLength
);

export const compareButtonText = createSelector(
  [resultsType, compareListLength],
  (type, length) => {
    let title = `Compare ${length} `;

    if (type === PLACE_RESULT_TYPE) title += 'Locations';
    else title += 'Providers';

    return title;
  }
);

export const compareTitle = createSelector(
  [compareButtonText, searchType, searchText, compareListLength],
  (buttonText, searchType, searchText, length) => {
    if (length === 0) return 'Compare';
    if (
      searchType !== CARE_CATEGORIES.FACILITY_TYPE &&
      searchType !== CARE_CATEGORIES.PROVIDER_SPECIALTY
    ) {
      return buttonText;
    }

    const [prefix, suffix] = buttonText.split(length); // returns ["Compare", "Providers"||"Locations"]

    return `${prefix} ${length} ${searchText} ${suffix}`;
  }
);

// Advanced selectors
export const totalAppliedFilters = createSelector([filters], (filters) => {
  let result = 0;

  for (const filterValue of Object.values(filters)) {
    const filterType = typeof filterValue;

    switch (filterType) {
      case 'object':
        result += filterValue?.length || 0;
        break;
      case 'boolean':
        if (filterValue === true) result += 1;
        break;
      default:
        // should never get here
        logDevMessage('Unhandled filter type in selectResults.totalAppliedFilters');
        break;
    }
  }
  return result;
});

export const boundsAsQueryParams = createSelector([southwestBound, northeastBound], (sw, ne) => {
  if (isValidCoords(sw?.latitude, sw?.longitude) && isValidCoords(ne?.latitude, ne?.longitude)) {
    return encodeURIComponent(`${ne.latitude},${ne.longitude}__${sw.latitude},${sw.longitude}`);
  }
  return '';
});

/** Returns an array of map features.
 * For Provider results, each every location on every provider is a map feature.
 * For Places, each place is a map feature.
 *
 * Map features have a featureId latitude and longitude.
 * You can also add additional properties like resultId to be stored with that map feature.
 */
export const mapFeatures = createSelector([all, coordinates], (resultObj, userCoords) => {
  const locations = []; // array of locations to return

  for (const result of Object.values(resultObj)) {
    let resultLocation;

    if (resultIsProviderType(result)) {
      // for providers, we need to return only the closest place
      resultLocation = result.places.reduce(
        (closestPlace, currentPlace) => {
          const distanceFromUser = distance(
            userCoords.latitude,
            userCoords.longitude,
            currentPlace.latitude,
            currentPlace.longitude
          );

          if (distanceFromUser < closestPlace.distance) {
            return {
              resultId: result.entityId, // id for referencing the result in the resultsObject
              featureId: result.entityId, // unique id for map rendered location
              latitude: currentPlace.latitude,
              longitude: currentPlace.longitude,
              distance: distanceFromUser,
              srText: `Location address for ${result.entityName}: ${currentPlace.address1} ${currentPlace.city} ${currentPlace.state} ${currentPlace.zipcode}. Opens ${PROVIDER_RESULT_TYPE} details.`,
            };
          }

          return closestPlace;
        },
        { distance: Infinity }
      );
    } else {
      resultLocation = {
        resultId: result.id, // id for referencing the result in the resultsObject
        featureId: result.id, // unique id for map rendered location
        latitude: result.latitude,
        longitude: result.longitude,
        distance: distance(
          userCoords.latitude,
          userCoords.longitude,
          result.latitude,
          result.longitude
        ),
        srText: `Address for ${result.name}: ${result.address1} ${result.city} ${result.state} ${result.zipcode}. Opens ${PLACE_RESULT_TYPE} details.`,
      };
    }

    locations.push(resultLocation);
  }
  return locations;
});

export const isClusterSelected = createSelector([clusterIds], (ids) => ids?.length > 0);

export const hasActiveMapMarker = createSelector(
  [selectedId, isClusterSelected],
  (id, isCluster) => id || isCluster
);

export const clusterResults = createSelector([clusterIds, all], (ids, resultsObject) =>
  ids.map((id) => resultsObject[id])
);

export const selectedResult = createSelector([selectedId, all], (id, resultsObject) => {
  if (!id) return undefined;
  return resultsObject[id];
});

export const hoveredIdEqualsIdProp = memoize((propId) =>
  createSelector([hoveredId], (hoveredId) => hoveredId === propId)
);

export const parentSpecialty = createSelector(
  [
    subspecialtyId,
    specialtyId,
    wasPlaceSearch,
    selectSpecialties.providerSpecialtiesMap,
    selectSpecialties.placeSpecialtiesMap,
  ],
  (subspecialtyId, specialtyId, wasPlaceSearch, providerSpecialtiesMap, placeSpecialtiesMap) => {
    if (!subspecialtyId) return null;
    return wasPlaceSearch ? placeSpecialtiesMap[specialtyId] : providerSpecialtiesMap[specialtyId];
  }
);

export const wasNotSubspecialtySearch = createSelector(
  [specialtyId, subspecialtyId],
  (specialtyId, subspecialtyId) => !subspecialtyId || (subspecialtyId < 0 && !!specialtyId)
);

/** Returns the specialty id of the specialty with the highest filter count */
export const highestFilterCountSpecialtyId = createSelector([filterCounts], (filterCounts) => {
  if (!filterCounts) return undefined;

  const { specialtyCounts = [] } = filterCounts;

  if (!specialtyCounts.length) return undefined;

  // we need to find the specialty in the array that has the highest count property
  const highestCountSpecialty = specialtyCounts.reduce((highestSpecialty, currentSpecialty) => {
    if (!highestSpecialty) return currentSpecialty; // if we have not yet set a highest specialty, the current one should be the highest

    // return the specialty with the higher count
    if (currentSpecialty?.count > highestSpecialty?.count) return currentSpecialty;
    return highestSpecialty;
  });

  // once we have the specialty with the highest count, return it's id
  return highestCountSpecialty?.specialtyId || undefined;
});

export const profileDetailsLink = (resultId) =>
  createSelector(
    [
      wasProviderSearch,
      selectNetworks.currentSlug,
      selectLocation.latLong,
      locationInput,
      selectLocation.locationComponents,
    ],
    (wasProviderSearch, currentSlug, latLong, locationInput, locationComponents) => {
      if (wasProviderSearch) {
        return `/profile/provider/${resultId}?network_slug=${currentSlug}&location=${
          latLong.latitude
        },${latLong.longitude}&location_input=${locationInput}&city=${
          locationComponents.city || ''
        }&state=${locationComponents.state || ''}`;
      }

      return `/profile/place/${resultId}?network_slug=${currentSlug}&location=${latLong.latitude},${
        latLong.longitude
      }&location_input=${locationInput}&city=${locationComponents.city || ''}&state=${
        locationComponents.state || ''
      }`;
    }
  );

/** This selector reads the specialtyId or subspecialtyId in the resultsSlice and checks
 * if there is a corresponding alternate search suggestion that has been mapped to return */
export const alternateSearchSuggestion = createSelector(
  [specialtyId, subspecialtyId],
  (searchedSpecialty, searchedSubspecialty) => {
    if (searchedSubspecialty) {
      return subspecialtySuggestionMap[searchedSubspecialty];
    }

    if (searchedSpecialty) {
      return specialtySuggestionMap[searchedSpecialty];
    }

    return undefined;
  }
);
